import React, { useEffect, useState } from 'react'
import { isSuperAdminAccess } from "../../utils"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../redux/actions/user"
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as commonActions from "../../redux/actions/common"

import {
    Grid,
    CircularProgress,
    TableContainer,
    Table,
    Button,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    Box
} from "@material-ui/core"
import CommonLoading from '../../components/common/CommonLoading'

function User() {

    const history = useHistory()
    const dispatch = useDispatch()

    const {
        allAdmins = [],
        totalAdmins = 0,
        allAdminLoader = false,
        adminLoader = false
    } = useSelector(state => state.user) || {}

     useEffect(() => {
        if (!isSuperAdminAccess()) {
            return history.push('/')
        }
        dispatch(actions.getAllAdmin())
    }, [])

    const handleConfirmation = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { onDeleteAdmin(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const onDeleteAdmin = (id) => {
        dispatch(actions.deleteUser(id)).then(res => {
            dispatch(actions.getAllAdmin())
        })
    }

    const onEditAdmin = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddUserModal"))
    }

    return (
        <Grid container component="main" className="admin-container">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="custom-button-div">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddUserModal")) }}
                            >Add User</Button>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mt={3}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.no</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            {/* <TableCell>Password</TableCell> */}
                                            <TableCell>Created At</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            Array.isArray(allAdmins) && allAdmins.length ? allAdmins.map((item, index) => {
                                                return <UserTableRow
                                                    item={item}
                                                    key={item._id}
                                                    index={index}
                                                    onDeleteAdmin={handleConfirmation}
                                                    onEditAdmin={onEditAdmin}
                                                />
                                            }) : <TableRow>
                                                <TableCell colSpan="5" align="center">No Admin Found.</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <CommonLoading isLoading={allAdminLoader} />
        </Grid>
    )
}

export default User


const UserTableRow = (props) => {
    const { item, index, onDeleteAdmin, onEditAdmin } = props
    const [show, setShow] = useState(false)
    // const deleteBtnCheck = superAdminCheck && !(userEmail === item.email);

    const onChangeShow = () => {
        setShow(!show)
    }
    return (
        <TableRow >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.email}</TableCell>

            {/* Password Table Row */}
            {/* <TableCell className="password-table-row">
                <span className="show-icon">{show ? <VisibilityIcon onClick={onChangeShow} /> : <VisibilityOffIcon onClick={onChangeShow} />}</span>
                <span className="password-span">
                    {show ? item.password : <span style={{ fontSize: 20 }}>{String(item.password).split('').map(() => <>&bull;</>)}</span>}
                </span>
            </TableCell> */}
            <TableCell>{new Date(item.createdDate).toLocaleDateString()}</TableCell>
            <TableCell><div>
            <span className="cur-pointer edit-icon" onClick={() => { onEditAdmin(item) }}><EditIcon /></span>
            <span className="cur-pointer delete-icon" onClick={() => onDeleteAdmin(item._id)}><DeleteOutlineIcon /></span>  

             
            </div></TableCell>
        </TableRow>
    )

}