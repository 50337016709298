import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import tabIcon from '../../assets/images/tabIcon.svg'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import { BASE_URL } from '../../constants';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import CustomInput from '../../components/common/CustomInput';
import CustomMultiSelect from '../../components/common/CustomMultiSelect';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    //userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "" : "",
    border: isDragging ? "none" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});


export default function DraggableList(props) {

    const {
        category = [],
        handleChangeSubCategory,
        handleDeleteCategory,
        values,
        onUpdateCategoryOrder,
        handleEditCategory
    } = props;

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            props.category,
            result.source.index,
            result.destination.index
        );
        onUpdateCategoryOrder(items)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <ul
                        className="category-ul-list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {category && category.map((item, index) => {
                            let imgUrl = (item && item.image) || ''
                            return <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided, snapshot) => (

                                    <li className="custon-list"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <MenuIcon className="mr-2" />
                                        <div className="category-img-sec">
                                            {
                                                imgUrl ? <img src={`${BASE_URL}${imgUrl}`} className="category-img" alt="" />
                                                    :
                                                    <CropOriginalIcon />
                                            }

                                        </div>
                                        <div className="left-section">
                                            <CustomInput
                                                name="categoryName"
                                                fullWidth
                                                value={item.categoryName || ""}
                                          
                                                onChange={() => { }}
                                                label="Category Name"
                                            />
                                        </div>
                                        <CustomMultiSelect
                                            label="Sub Category"
                                            name={"name"}
                                            fullWidth
                                            options={values || []}
                                            handleChange={(e, val) => { handleChangeSubCategory(e, val, index) }}
                                            value={item.blazeCategories || []}
                                            className={"multiwidth"}
                                        />
                                         <div className="  ml-1 cur-pointer edit-icon" onClick={() => { handleEditCategory(item) }}>
                                                            <EditIcon />
                                                        </div>
                                                        <div className=" ml-1 cur-pointer delete-icon" onClick={() => { handleDeleteCategory(item._id) }}>
                                                            <DeleteOutlineIcon />
                                                        </div>
                                        
                                    </li>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
}
