import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as promotionActions from "../../redux/actions/promotions"

import CustomMultiSelect from '../../components/common/CustomMultiSelect';

function AddSpecialProduct(props) {
    const classes = useStyles();

    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { allProducts } = useSelector(state => state.products)
    const { specialProducts } = useSelector(state => state.promotions)

    const { values: spAddedProducts = [] } = specialProducts

    const { values = [] } = allProducts


    const [data, setData] = useState([])


    const addProduct = () => {
        let updatedSpProducts = [...spAddedProducts]
        updatedSpProducts.push(data)

        let newOrderIds = updatedSpProducts.map(v => v.id)

        dispatch(promotionActions.updateSpecialProducts({ products: newOrderIds })).then(() => {
            dispatch(promotionActions.getSpecialProducts())
            props.closeModal()
        }).catch(() => { })
    }

    const handleChangeSpecialProduct = (e, val) => {
        setData(val)
    }

    const filteredProduct = [...values].filter(item => {
        const isExists = (Array.isArray(spAddedProducts) && spAddedProducts.find(spProd => spProd.id === item.id)) || false
        return !isExists
    })

    return (
        <Grid container component="main" className={`${classes.root} add-special-modal-container`}>

            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">Add Product</Typography>
            </Grid>
            <Grid item xs={12} className="add-product-input">
                <CustomMultiSelect
                    multiple={false}
                    label="Add Product"
                    name={"name"}
                    options={filteredProduct || []}
                    handleChange={(e, val) => { handleChangeSpecialProduct(e, val) }}
                    value={data || {}}
                    className={"w-100"}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} className="d-flex jc-ctr pb-2">
                <Button onClick={() => { addProduct() }} variant="contained" color="primary" fullWidth>Submit</Button>
            </Grid>
        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddSpecialProduct