import './App.css';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {
  Login,
  ManageProductsPage,
  ManageCategoriesPage,
  SingleProductPage,
  AppointmentsPage,
  PromotionsPage,
  User,
  Setting,
  TrucksPage,
  AddTruck,
  EditTruck,
  NotificationPage,
  Orders,
  DeliveryHours,
} from './containers';
import { isLoggedIn } from "./utils"
import CustomDrawer from './components/common/CommonDrawer';
import CommonModal from "./components/common/CommonModal"

import { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import * as authActions from "./redux/actions/auth"
import Brands from './containers/Brands';

var id = null

function Routes() {
  const dispatch = useDispatch()


  useEffect(() => {
    id = setInterval(timer, 900000);

    return () => {
      clearInterval(id)
    }
  }, [])

  function timer() {
    dispatch(authActions.getLastSyncDate())
  }

  return (
    <Switch>
      <PublicRoute path="/login" component={Login} />
      <PrivateRoute path="/manage_products/:id" component={SingleProductPage} />
      <PrivateRoute path="/manage_products" component={ManageProductsPage} />
      <PrivateRoute path="/manage_category/" component={ManageCategoriesPage} />
      {/* <PrivateRoute path="/service_areas" component={AppointmentsPage} /> */}
      <PrivateRoute path="/promotions" component={PromotionsPage} />
      <PrivateRoute path="/orders" component={Orders} />
      {/* <PrivateRoute path="/terminal/add" component={AddTruck} /> */}
      {/* <PrivateRoute path="/terminal/edit/:id" component={EditTruck} /> */}
      {/* <PrivateRoute path="/terminals" component={TrucksPage} /> */}
      {/* <PrivateRoute path="/notification" component={NotificationPage} /> */}
      <PrivateRoute path="/delivery_hours" component={DeliveryHours} />
      <PrivateRoute path="/brands" component={Brands} />

      <PrivateRoute path="/users" component={User} />
      <PrivateRoute path="/settings" component={Setting} />
      <Route path="/">
        <Redirect to="/manage_products" />
      </Route>

    </Switch>
  );
}

export default Routes;

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )
      }
    />
  )
}


const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          isLoggedIn() ? (
            <>
              <CustomDrawer >
                <Component {...props} />
              </CustomDrawer>

              <CommonModal />
            </>
          )
            :
            <Redirect to={`/login?redirect=${props.location.pathname}`} />
        )
      }}
    />
  )
}