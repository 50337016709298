import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import * as productActions from "../../redux/actions/products"
import { toast } from 'react-toastify';

import ProductCommonCard from './ProductCommonCard';
import EditProductDetails from './EditProductDetails';
import CommonLoading from '../../components/common/CommonLoading';


const intialState = {
    strainType: "",
    infoEffects: [],
    brandDescription: "",
    websiteProductName: "",
    disclaimerText: "",
    metaTitle: "",
    metaDescription: "",
    focusKeyword: [],
    seoContent: "",
    productDescription: "",
    isCopyText: false,
};

function SingleProductDetails(props) {
    // const classes = useStyles();
    // const history = useHistory();
    const dispatch = useDispatch();

    let productId = props?.match?.params?.id || "";

    const { isLoading, infoAndEffectsData, singleProductDetailData } = useSelector((state) => state.products)

    const [data, setData] = useState(intialState);


    const [productStatus, setProductStatus] = useState(false)

    useEffect(() => {
        dispatch(productActions.getInfoAndEffect())
        dispatch(productActions.getSingleProductDetails(productId))
    }, [])

    useEffect(() => {
        onRefreshProductDetails()
    }, [singleProductDetailData])

    const onRefreshProductDetails = () => {
        let updatedEffects = [...(singleProductDetailData?.productEffects || [])]?.map(v => v.title)
        setData({
            ...data,
            infoEffects: updatedEffects || [],
            strainType: (singleProductDetailData?.flowerType?.toLowerCase()) || "",
            brandDescription: (singleProductDetailData?.brandDescription) || "",
            websiteProductName: (singleProductDetailData?.websiteProductName) || "",
            metaTitle: singleProductDetailData?.metaTitle || "",
            metaDescription: singleProductDetailData?.metaDescription || "",
            focusKeyword: singleProductDetailData?.focusKeyword,
            seoContent: singleProductDetailData?.seoContent || "",
            productDescription: singleProductDetailData?.productDescription || "",
            disclaimerText: singleProductDetailData?.disclaimerText || "",
            isCopyText: singleProductDetailData?.isCopyText || false,
        })
        setProductStatus((singleProductDetailData?.showInWidget) || false)
    }

    const onChangeCheckbox = (e, name) => {
        e.preventDefault();

        let updatedEffects = [...(data?.infoEffects || [])]
        let alreadyExist = updatedEffects.findIndex(v => v === name)
        if (alreadyExist === -1) {
            updatedEffects.push(name)
        } else {
            updatedEffects.splice(alreadyExist, 1)
        }
        setData({ ...data, infoEffects: updatedEffects })
    }


    const onChangeBrandDescription = (e) => {
        const { name, value, checked } = e.target;

        if (name === "focusKeyword") {
            const strToArr = value.split(',')
            setData({
                ...data,
                [name]: strToArr
            })
        }
        if (name === "isCopyText") {
            setData({
                ...data,
                [name]: checked
            })
        }
        else {

            setData({
                ...data,
                [name]: value
            })
        }
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        dispatch(productActions.updateSingleProduct(productId, data)).then(() => {
            toast.success('Product updated successfully')
        }).catch(() => { onRefreshProductDetails() })
    }

    const onChangeProductStatus = () => {
        setProductStatus(!productStatus)
        dispatch(productActions.updateSingleProduct(productId, { active: !productStatus })).then(() => {
            toast.success('Product status updated successfully')
        }).catch(() => { onRefreshProductDetails() })
    }
    return (
        <Grid item xs={12}>
            <Grid container className="single-prduct-detail-container">
                <Grid item xs={12} md={9} className="left-sec">
                    <EditProductDetails
                        infoAndEffectsData={infoAndEffectsData}
                        singleProductDetailData={singleProductDetailData}
                        data={data}
                        productStatus={productStatus}
                        onChangeProductStatus={onChangeProductStatus}
                        onChangeCheckbox={onChangeCheckbox}
                        handleSubmit={handleSubmit}
                        onChangeBrandDescription={onChangeBrandDescription}
                    />
                </Grid>
                <Grid item xs={12} md={3} className="right-sec">
                    <ProductCommonCard data={singleProductDetailData} />
                </Grid>

                <CommonLoading isLoading={isLoading} />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default SingleProductDetails