import React from 'react';
import { Grid, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomTable from "../../components/common/CustomTable"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
// import CropOriginalIcon from '@material-ui/icons/CropOriginal';
// import { BASE_URL } from '../../constants';

function AllPromotions(props) {
    const classes = useStyles();
    const headings = [
        "S.no",
        // "Image",
        "Title",
        "Description",
        "Status",
        "Promocode",
        "Actions"
    ];

    const { handleDeletePromotion, promotionsValues, handleEditPromotion, isLoading } = props;

    return (
        <Grid container component="main" className={`${classes.root} all-promotions-container`}>
            <Grid item xs={12} className="promotions-table">
                <CustomTable
                    tableHeading={headings}
                    rowsData={createDataProducts(promotionsValues, {
                        handleDeletePromotion,
                        handleEditPromotion
                    })}
                    isLoading={isLoading}
                />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AllPromotions


function createDataProducts(data, action) {
    const { handleDeletePromotion, handleEditPromotion } = action

    return data?.length ?
        data?.map((item, index) => {
            const {
                id,
                // status, 
                name,
                // promoCodes,
                //  title,
                promoDesc,
                active,
                // images = []
            } = item;

            // let imgUrl = images?.length && images[0] || null;
            // let promoCodesJoined = promoCodes.join(", ");

            return <React.Fragment key={index}>
                <TableCell >{index + 1}</TableCell>
                {/* <TableCell>
                    {
                        imgUrl ? <img src={`${BASE_URL}${imgUrl}`} className="" height={30} alt="" />
                            :
                            <CropOriginalIcon />
                    }
                </TableCell> */}
                <TableCell>{name || "-"}</TableCell>
                <TableCell>{promoDesc || "-"}</TableCell>
                <TableCell > <div className={`statusActiveInactive ${active}`}>{active ? "Active" : "Inactive" || "-"}</div> </TableCell>
                {/* <TableCell className="promo-name">{promoCodesJoined || "-"}</TableCell> */}
                <TableCell className="promo-name">{name || "-"}</TableCell>

                <TableCell>
                    <>
                        <span className="cur-pointer edit-icon" onClick={() => { handleEditPromotion(item) }}><EditIcon /></span>
                        <span className="cur-pointer delete-icon" onClick={() => { handleDeletePromotion(id) }}><DeleteOutlineIcon /></span>
                    </>
                </TableCell>
            </React.Fragment>
        }) : []
}