import React, { useEffect, useState } from 'react'
import * as actions from "../../redux/actions/products"
import * as commonActions from "../../redux/actions/common"
import { Grid, Button, CircularProgress,InputAdornment, TableCell, Box } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import CustomTable from '../../components/common/CustomTable'
import CustomInput from '../../components/common/CustomInput'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';


export default function Brands() {

    const [search, setSearch] = useState("")
    const dispatch = useDispatch()
    const { allBmwBrands = {} } = useSelector(state => state.products)
    const { values: bmwBrands = [] } = allBmwBrands || {}
    const [filters, setFilters] = useState({
        limit: 10,
        skip: 0,
        page: 0,
    })
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        dispatch(actions.getAllBrandsByProducts())
        dispatch(actions.getAllBmwBrands())
    }, [])

    const onEditBrand = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddBrandModal"))
    }

    const onDeleteBrand = (id) => {
        dispatch(actions.deleteBrandFromBmw(id)).then(() => {
            dispatch(actions.getAllBmwBrands())
        })
    }

    let filtersBmwBrands = bmwBrands
    if (searchTerm) {
        filtersBmwBrands = bmwBrands.filter(item => String(item.brandName).toLowerCase().includes(searchTerm.toLowerCase()))
    }


    const handleConfirmationPromotion = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { onDeleteBrand(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }
    const onClickClearInputField = () => {
        if (!searchTerm) return
        const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: '' }
        setFilters(newFilters)
        // hitRequest({ ...newFilters, cartType: isActiveTab })
        setSearchTerm('')
    }
 
    return (
        <Grid container className="promotions-container">
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mb={2}>
                <Grid item xs={12} >
                <Grid container>
                <Grid item xs={4} className="d-flex search-section">
                        <CustomInput
                            value={searchTerm}
                            label="Search"
                            name="search"
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder="Search by Brand name"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                endAdornment: searchTerm ? <InputAdornment position="end" className="cur-pointer"><ClearIcon onClick={onClickClearInputField} /></InputAdornment> : null
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} className="manage-top-tabs"></Grid>
                    <Grid item xs={4} className="manage-top-tabs">

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddBrandModal")) }}
                        className=""
                    >
                        Add Brand
                    </Button>
                    </Grid>
                    </Grid>
                    </Grid>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <CustomTable
                    tableHeading={["S.no", "Brand Name", "Description", "Actions"]}
                    rowsData={createZipCodeTableCell(filtersBmwBrands, onEditBrand, handleConfirmationPromotion)}
                />
            </Grid>
        </Grid>
    )
}


function createZipCodeTableCell(data = [], onEditBrand = () => { }, handleConfirmationPromotion = () => { }) {

    return data && data && data.length ?
        data.map((item, index) => {
            const { brandId, brandName = '', description = '' } = item
            return <React.Fragment key={brandId}>
                <TableCell >{index + 1}</TableCell>
                <TableCell >{brandName}</TableCell>
                <TableCell style={{ maxWidth: "600px" }}>{description}</TableCell>
                <TableCell>
                <>
            <span className="cur-pointer edit-icon" onClick={() => { onEditBrand(item) }}><EditIcon /></span>
            <span className="cur-pointer delete-icon" onClick={() => { handleConfirmationPromotion(brandId) }}><DeleteOutlineIcon /></span>

                </>
                    {/* <EditIcon className="cur-pointer edit-icon" onClick={() => onEditBrand(item)} /> */}
                    {/* <DeleteOutlineIcon className="cur-pointer delete-icon" onClick={() => handleConfirmationPromotion(brandId)} /> */}
                </TableCell>
            </React.Fragment>
        }) : []
}