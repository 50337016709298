import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { CircularProgress, Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { validateLogin } from '../../utilities/validations/auth';
import * as authActions from "../../redux/actions/auth"


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        height: "100%",
        width: '100%',
    },
    innerImage: {
        width: '100%',
        height: "100%",
        objectFit: "cover"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const [user, setUser] = useState({
        email: '',
        password: ''
    })
    const [errors, setErrors] = useState({})
    const [imageLoader, setImageLoader] = useState(true);

    const { isLoading } = useSelector(state => state.auth)



    const onChangeHandler = ({ target: { value = '', name = '' } }) => {
        setUser({ ...user, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (isLoading) return

        const { errors, isValid } = validateLogin(user)
        setErrors(errors);

        if (isValid) {
            dispatch(authActions.login(user)).then(() => {
                history.push("/manage_products")
            }).catch(() => { })
        }
    }

    const LoaderStyle = {
        height: "100%",
        overflow: "hidden"
    }

    return (
        <div className="login-background-contianer">
            <Grid container component="main" className={`${classes.root} login-container`}>
                {/* <div className="login-container-div"> */}
                <Grid item xs={12} sm={12} md={10} lg={8} className="login-container-item">
                    <Paper style={{ height: "85vh" }}>
                        <Grid container className="" style={{ height: "100%" }}>
                            <Hidden only={['xs', 'sm']}>
                                <Grid item md={6} className={classes.image}>
                                    <img src="/images/login_bg.jpg" alt='' onLoad={() => setImageLoader(false)} className={classes.innerImage} />
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} sm={12} md={6} style={{ display: "flex" }}>
                                <Grid container>
                                    <Grid item xs={12} sm={10} md={10} className="form-container-item">
                                        <div className={classes.paper}>

                                            {/* <LockOutlinedIcon /> */}
                                            <div className="login-logo-div">
                                                {/* <img src="/images/login_logo.png" alt="logo" /> */}
                                                <img src="/images/logo.png" alt="logo" />


                                            </div>
                                            <form className={`${classes.form} 
                                        `} onSubmit={onSubmit} noValidate>
                                                <TextField
                                                    type="text"
                                                    variant="outlined"
                                                    // margin="normal"
                                                    // required
                                                    fullWidth
                                                    id="email"
                                                    label="Email Address"
                                                    name="email"
                                                    error={errors && errors.email ? !!errors.email : !!''}
                                                    value={user.email}
                                                    onChange={onChangeHandler}
                                                    autoComplete="email"
                                                    autoFocus
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    // margin="normal"
                                                    // required
                                                    fullWidth
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    error={errors && errors.password ? !!errors.password : !!''}
                                                    value={user.password}
                                                    onChange={onChangeHandler}
                                                    autoComplete="current-password"
                                                />
                                                {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    style={{ height: 50 }}
                                                    onClick={onSubmit}
                                                >
                                                    {isLoading ? <CircularProgress size={25} style={{ color: "white" }} /> : 'Login'}
                                                </Button>
                                                <Grid container>
                                                    {/* <Grid item xs>
                                <Link style={{ float: "right" }} href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}
                                                    {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid> */}
                                                </Grid>
                                            </form>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid >
        </div>
    );
}